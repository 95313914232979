@import "../../styles/variables.scss";

.main {
    background: #f5f5f5;
}

.bg-card-gradient {
    background-image: $falcon-card-gradient;
    background-position: center;
}

.bg-holder {
    position: absolute;
    width: 100%;
    min-height: 100%;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    will-change: transform,opacity,filter;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background-repeat: no-repeat;
    z-index: 0;
}

.link-pulpal { 
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px !important;
    font-weight: 800 !important;
}

.labels-color {
    color: $labels-color;
}

.pulpalSvg {
    margin-right: 10px;
}

.user-rules {
    color: $falcon-primary;
    text-decoration: none;
    font-size: 14px;
}

.user-rules:hover {
    color: $falcon-primary-hover;
    text-decoration: underline;
}

.soc-net-block {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #9DA9BB;
    font-weight: 350;
}

.hr {
    position: absolute;
    top: 12px;
    left: 0;
    height: 1px;
    width: 100%;
    background-color: silver;
}

.hr-text {
    z-index: 100;
    background-color: #fff;
    padding: 0 5px;
}

.reg {
    background-color: $falcon-primary;
    border: 1px solid $falcon-primary;
    font-weight: 500;
}

.reg:hover {
    background-color: $falcon-primary-hover;
    border: 1px solid transparent;
}

.reg-label {
    font-size: 1.728rem;
    color: $titles-color;
    margin-bottom: 5px;
}

.to-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.bg-half-circle {
    background-image: url(../../assets/registration/half-circle.png);
    z-index: 1000;
}

.bg-auth-circle-shape {
    position: absolute;
    right: -8.75rem;
    top: -5.125rem;
}

.bg-auth-circle-shape-2 {
    position: absolute;
    left: -6.25rem;
    bottom: -2.4375rem;
}

.text-white {
    --falcon-text-opacity: 1;
    font-size: 18px;
}

.opacity-75 {
    opacity: .75 !important;
}

.btn-outline-google-plus {
    color: $google-btn;
    border: 1px solid $google-btn;
}

.btn-outline-google-plus:hover {
    background-color: $google-btn;
    color: #fff;
    border: 1px solid transparent;
}

.btn-outline-facebook {
    color: $fb-btn;
    border: 1px solid $fb-btn;
}

.btn-outline-facebook:hover {
    background-color: $fb-btn;
    color: #fff;
    border: 1px solid transparent;
}

.phone-input-wrapper {
    position: relative;
}

.phone-input-prefix {
    position: absolute;
    top: 36px;
    left: 13.5px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
}

.phone-input {
    padding-left: 49.5px !important;
}

.btn-hover {
    font-weight: 500;
}

.btn-hover:hover {
    color: $labels-color;
}

.form-check-input:checked {
    background-color: #10cad1;
    border-color: #10cad1;
}

//Registration two

.check {
    font-size: calc(1.269rem + 0.228vw);
    color: $titles-color;
}

.label-huge {
    font-size: 18px;
    font-weight: 350;
}

.text-code {
    color: #748194;
    text-decoration: none;
    font-weight: 350;
}

.text-code:hover {
    text-decoration: underline;
}

.text-size {
    font-size: 17px;
}

// Registration three
.bg-holder-img {
    position: absolute;
    width: 100%;
    min-height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    will-change: transform,opacity,filter;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background-repeat: no-repeat;
    z-index: 0;
    background: url(../../assets/registration/reg-image.jpg);
}

.holder-img {
    background: url(../../assets/registration/reg-image.jpg);
    background-size: cover;
    background-position: center;
}

.appeal {
    font-size: calc(1.2978rem + 0.5736vw);
    color: $titles-color;
}


.passwordWrapper {
    display: flex;
    gap: 5px;
}

.wrap {
    padding-right: 5px;
}

.errorOtpMessage {
    margin: 10px 0 0;
    color: red;
}

.websiteLabelWrapper {
    overflow: hidden;
}

.websiteLabel {
    white-space: nowrap;
}

.userRules {
    margin-top: 10px;
}

.errorInputs {
    border: 1px solid red;
}

.inputsSpecial {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: red;
    background-clip: padding-box;
    border-radius: 0.375rem;
}

.errorInputs:focus {
    color: #212529;
    background-color: #fff;
    outline: 0;
    // box-shadow: 0 0 0 0.15rem rgba(235, 40, 20, 0.7);
    // box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.relativeInput {
    position: relative;
}

.phoneInput {
    padding-left: 46px !important;
}

.p-icon {
    margin-bottom: 8px;
}

.btnCnt {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

@media  (max-width: 1474px) {
    .regForm {
        padding: 39px !important;
    }    
}

@media  (max-width: 1400px) {
    .regForm {
        padding: 35px !important;
    }    
}

@media (max-width: 992px){
    .bg-auth-circle-shape, .bg-auth-circle-shape-2 {
        display: none;
    }
}

@media (min-width: 768px){
    .bg-auth-card-shape {
    background-position: 0 133%;
    }
}

@media (min-width: 768px){
    .col-md-5 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 41.66666667%;
    }
}

@media (max-width: 576px){
    .passwordWrapper {
        display: flex;
        justify-content: column;
        flex-wrap: wrap;
    }
}

@media (max-width: 576px){
    .wrap {
        display: flex;
        justify-content: column;
        flex-wrap: wrap;
        width: 100%;
    }
}