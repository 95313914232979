.paymentList {
  max-width: 1200px !important;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 65vh;
  padding: 20px;
}

.paymentListFiltered {
  max-width: 1200px !important;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 65vh;
  padding: 20px;
}

.paymentListInner {
  overflow: hidden;
  // min-width: 1200px;
  width: 100%;
}

.listWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.paymentListInnerFiltered {
  overflow: hidden;
  // min-width: 1200px;
  width: 100%;
} 

.card-item {
  height: 124px;
  display: flex;
  width: 380px;
}

.card-item-filtered {
  height: 124px;
  display: flex;
  width: 380px;
}

.service-card {
  width: 100%;
}

.not-server {
  display: flex;
  height: 200px;
  justify-content: center;
  align-items: center;
}

.loader-container {
  display: flex;
  width: 100px;
}

.skeleton-width {
  display: flex;
}
.skeleton-width975 {
  display: none;
}
.skeleton-width767 {
  display: none;
}
.skeleton-width380 {
  display: none;
}

.card-body-item {
  width: 1200px;
}

.text-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.payments-link {
  color: #10CAD1;
  font-weight: 400;
}

.item-localename {
  font-size: 16px;
  font-weight: normal;
}

@media (max-width: 1539px) {
  .paymentListInner {
    display: flex;
    margin: 0 auto;
    width: 818px;
  }

  .listWrap {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .card-item {
    width: 399px;
  }
  .card-item-filtered {
    width: 435px;
  }
}

@media (max-width: 994px) {
  .card-item {
    width: 100%;
  }
  .card-item-filtered {
    width: 100%;
  }
}

@media (max-width: 975px) {
  .skeleton-width {
    display: none;
  }
  .skeleton-width975 {
    display: flex;
  }
  .skeleton-width767 {
    display: none;
  }
  .skeleton-width380{
    display: none;
  }
  .card-item {
    width: 100%;
  }
  .card-item-filtered {
    width: 100%;
  }
}


@media (max-width: 768px) {
  .skeleton-width767 {
    display: flex;
  }
  .skeleton-width {
    display: none;
  }
  .skeleton-width975 {
    display: none;
  }
  .skeleton-width380{
    display: none;
  }
}

@media (max-width: 380px) {
  .skeleton-width380 {
    display: flex;
  }
  .skeleton-width767 {
    display: none;
  }
  .skeleton-width {
    display: none;
  }
  .skeleton-width975 {
    display: none;
  }
}