.form-control:focus {
    color: #344050;
    background-color: #fff;
    border-color: #88e5e8;
    outline: 0;
    -webkit-box-shadow: inset(0 1px 2px rgba(0,0,0,0.075)), 0 0 0 0.25rem rgba(16, 202, 209, 0.25);
    box-shadow:inset(0 1px 2px rgba(0,0,0,0.075)), 0 0 0 0.25rem rgba(16, 202, 209, 0.25);
}

.defInput {
    width: 336px;
    padding-left: 2rem;
    padding-right: 2rem;
    line-height: 1.7rem;
    border-radius: 50rem;
    -webkit-box-shadow: none;
    box-shadow: none;
    line-height: 38px;
    position: relative;
    padding: 0 2rem;
    border-color: rgb(216, 226, 239);
    border-radius: 50px;
    height: 40px;
}

.defInput::placeholder {
    color: rgb(216, 226, 239);
}

.defInput:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: rgb(216, 226, 239);
}
  
.defInput::-ms-input-placeholder { /* Microsoft Edge */
    color: rgb(216, 226, 239);
}

.search-box .search-input {
    padding-left: 2rem;
    padding-right: 2rem;
    line-height: 1.7;
    border-radius: 50rem;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.lupa {
    color: rgb(216, 226, 239);
}

@media (max-width: 992px){
    .form-control {
        display: flex !important; 
    }
}

@media (max-width: 400px) {
    .defInput {
        width: calc(100% + 7px);
    }
}