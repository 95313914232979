.restoreWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
    background-color: #D9D9D9;
}

.restoreInner {
    // width: 30%;
    background-color: #fff;
    padding: 35px 25px;
}

.restoreTitle {
  margin-bottom: 30px;
}

// ----------------------- NEW PASSWORD -----------------------

.newPasswordWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  background-color: #D9D9D9;
}

.newPasswordInner {
  // width: 20%;
  width: 320px;
  min-width: 240px;
  background-color: #fff;
  padding: 35px 25px;
  position: relative;
}

.passwordWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 30px;
}

.newPasswordTitle {
  text-align: center;
  margin-top: 20px;
}

.wrap {
  width: 100%;
}

.titlePass {
  position: absolute;
  top: 5px;
  left: 10px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  font-size: 26px;
  // background-color: red;
}

// ----------------------- OTP -----------------------

.otpWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  background-color: #D9D9D9;
}

.otpInner {
    min-width: 270px;
    width: 30%;
    background-color: #fff;
    padding: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    color: #292929;
    padding-bottom: 40px;
}

.type {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  font-size: 26px;
}

.typecode {
  text-align: center;
}
.otpTitle {
  font-size: 20px;
  text-align: center;
}

.resend {
  cursor: pointer;
  color: #10CAD1;
  font-size: 18px;
  font-weight: 500;
}

.otpForm {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.otpCodeInputWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.otpInputWrapper {
  width: 46px;
  border: 1px solid #CED4DA;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.otpInputWrapperError {
  width: 46px;
  border: 1px solid red;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.otpInputs {
  display: flex;
  width: 100%;
}

.otpInput {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 44px;
  border-color: #CED4DA;
  border: none;
  border-radius: 6px;
  outline: none;
}

.btnCnt {
  width: 100%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.info {
  position: absolute;
  top: 20px;
  right: 10px;
  cursor: help;
  display: flex;
  flex-wrap: wrap;
}

.infoText {
  position: absolute;
  top: -60px;
  right: 0;
  padding: 10px;
  background-color: #10CAD1;
  border-radius: 25px;
  z-index: 100;
  display: none;
  width: 220px;
  color: #fff;
  text-align: center;
}

.info:hover .infoText {
  display: flex;
  align-items: center;
  justify-content: center;
}

// INFO NEW PASS >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

.infoNewPass {
  position: absolute;
  top: 12px;
  right: 35px;
  cursor: help;
}

.infoTextPass {
  position: absolute;
  top: -130px;
  right: -40px;
  padding: 5px;
  background-color: #10CAD1;
  border-radius: 25px;
  z-index: 100;
  display: none;
  width: 280px;
  color: #fff;
  text-align: center;
}

.infoNewPass:hover .infoTextPass {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

// INFO NEW PASS TWO >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

.infoNewPassTwo {
  position: absolute;
  top: 12px;
  right: 35px;
  cursor: help;
}

.infoTextPassTwo {
  position: absolute;
  top: -220px;
  right: -40px;
  padding: 5px;
  background-color: #10CAD1;
  border-radius: 25px;
  z-index: 100;
  display: none;
  width: 280px;
  color: #fff;
  text-align: center;
}

.infoNewPassTwo:hover .infoTextPassTwo {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}


.listFlexible {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}

@media (max-width: 420px) {
  .restoreInner {
    width: 280px;
  }
}

@media (max-width: 340px) {
    .newPasswordInner {
      width: 260px;
    }
    .infoTextPass {
      position: absolute;
      top: -150px;
      right: -50px;
      padding: 5px;
      background-color: #10CAD1;
      border-radius: 25px;
      z-index: 100;
      display: none;
      width: 240px;
      color: #fff;
      text-align: left;
    }
    .infoTextPassTwo {
      position: absolute;
      top: -240px;
      right: -50px;
      padding: 5px;
      background-color: #10CAD1;
      border-radius: 25px;
      z-index: 100;
      display: none;
      width: 240px;
      color: #fff;
      text-align: left;
    }
}