.header {
    margin-bottom: 70px;

    &__top {
        box-shadow: 0 1px 0 #999999;
    }

    &__link {
        display: block;
        margin-right: 30px;
        line-height: 40px;
        font-size: 14px;
        font-weight: 500;
        color: #707070;
        text-decoration: none;
        border-bottom: 2px solid transparent;
        transition: 0.4s all;

        &:hover {
            color: #10CAD1;
            border-bottom: 2px solid #10CAD1;
        }
    }
}
  
.nav1__links {
    display: block;
    margin: 0 18px;
    padding: 10px 10px;
    color: #292929;
    font-weight: 700;
    font-size: 14px;
    text-decoration: none;
    transition: 0.4s all;
    white-space: nowrap;

    &:hover {
        color: #10CAD1;
    }
}

.nav2__links {
    display: block;
    margin: 0 18px;
    padding: 5px 10px;
    color: #292929;
    font-weight: 700;
    font-size: 14px;
    text-decoration: none;
    transition: 0.4s all;
    white-space: nowrap;

    &:hover {
        color: #141414 !important;
    }
}

.popUp {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 5px;
    border-radius: 6px;
    padding: 10px 40px !important;
    cursor: pointer;
}

.popUpWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 5px;
    border-radius: 6px;
    padding: 10px;
    cursor: pointer;
    position: relative;
    min-width: 200px;
    max-width: 200px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.popUpHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    min-width: 200px;
    max-width: 200px;
    &:hover .profileIcon {
        filter: invert(60%) sepia(48%) saturate(853%) hue-rotate(134deg) brightness(98%) contrast(87%);
    }
    
    &:hover .username {
        filter: invert(60%) sepia(48%) saturate(853%) hue-rotate(134deg) brightness(98%) contrast(87%);
    }
    
    &:hover .arrow {
        filter: invert(60%) sepia(48%) saturate(853%) hue-rotate(134deg) brightness(98%) contrast(87%);
    }
    
    &:hover .arrowTop {
        filter: invert(60%) sepia(48%) saturate(853%) hue-rotate(134deg) brightness(98%) contrast(87%);
    }
    
}

.popUpContent {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    top: 70px;
    border-radius: 6px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    padding: 10px 30px;
    min-width: 200px;
    max-width: 200px;
    gap: 10px;
    background-color: #fff;
}

.arrowTop {
    transform: rotate(180deg);
}

.popUpContentNone {
    display: none;
}

.username {
    font-weight: 500px;
    color: #292929;
    text-transform: uppercase;
}

.userProfile {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    width: 100%;
    color: #292929;
    &:hover .profileIconContent {
        filter: invert(60%) sepia(48%) saturate(853%) hue-rotate(134deg) brightness(98%) contrast(87%);
    }
    &:hover .profileWord {
        filter: invert(60%) sepia(48%) saturate(853%) hue-rotate(134deg) brightness(98%) contrast(87%);
    }
}

.userProfileMob {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.profileIconContent { 
    transform: scale(0.8);
}

.line {
    height: 1px;
    width: 200px;
    background-color: silver;
}

.profileWord {
    color: #292929;
    font-weight: 700;
    font-size: 15px;
    text-transform: uppercase;
}

.profileIconContent {
    filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(300deg) brightness(99%) contrast(166%);
}

.exit {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    background-color: inherit;
    margin-left: 8px;
    border: none;
    font-weight: 700;
    font-size: 15px;
    width: 80px;
    color: #292929;
    text-transform: uppercase;
    text-decoration: none;
    &:hover  .exitWord {
        filter: invert(60%) sepia(48%) saturate(853%) hue-rotate(134deg) brightness(98%) contrast(87%);
    }
    &:hover  .exitIcon {
        filter: invert(60%) sepia(48%) saturate(853%) hue-rotate(134deg) brightness(98%) contrast(87%);
    }
}

.mobMenuProfile {
    display: none;
}

.usernameMob {
    font-weight: 500px;
    color: #292929;
    font-weight: 700;
    font-size: 15px;
    text-transform: uppercase;
}

.minWidthSelect {
    min-width: 70px;
}

@media (max-width: 1200px) {
    .nav1__links {
        margin: 0 10px;
        font-size: 12px;
    }

    .nav2__links {
        margin: 0 10px;
        font-size: 12px;
    }

    .navbar-brand {
        img {
            width: 120px;
        }
    }
}

@media (max-width: 992px) {
    .popUp {
        display: none;
    }
    .mobMenuProfile {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        gap: 10px;
        margin-left: 20px;
    }
    .exit {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 0;
    }
    .langSelect {
        margin-left: -4px !important;
    }
    .helpIcon {
        margin-left: 20px !important;
    }
}

@media (max-width: 576px) {
    .nav1 {
        box-shadow: rgba(41, 41, 41, 0.1) 0px 2px 5px;

        ul {
            padding: 20px;
        }

        &__links {
            font-size: 15px;
            padding: 10px;
            margin: 0;
            border-bottom: 1px solid #f5f5f5;
        }
    }
     .langSelect {
        margin-left: -13px !important;
    }
    .helpIcon {
        margin-left: 11px !important;
    }
    .btnFlexible {
        margin-left: 10px !important;
    }
}