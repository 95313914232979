.map {
    margin-bottom: 100px;
    margin-top: 100px;
  
    &__inner {
        border-radius: 20px;
        overflow: hidden;
        height: 500px;

        @media (max-width: 768px) {
            height: 350px;
        }

        @media (max-width: 576px) {
            height: 250px;
        }
    }
}

.contacts {
    margin-bottom: 100px;

    a {
        text-decoration: none;
        color: inherit;

        &:hover {
            color: inherit;
        }
    }

    &__card {
        padding: 30px;
        text-align: center;
        background-color: #fff;
        border-radius: 30px;
        box-shadow: 0 0 0 1px rgba(153, 153, 153, 0.5);
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
        transition: 0.4s all;

        img {
            margin-bottom: 10px;
        }

        &:hover {
            box-shadow: 5px 20px 60px 15px rgba(41, 41, 41, 0.2);
            -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
            -webkit-filter: grayscale(0);
            filter: grayscale(0);
        }

        &-title {
            font-size: 16px;
            font-weight: 700;
            margin: 15px auto;

            @media (max-width: 992px) {
                margin-top: 0;
            }
        }

        &-desc {
            font-weight: 400;
            font-size: 12px;
            color: #292929;
            line-height: 1.3;

            @media (max-width: 768px) {
                font-size: 13px;
            }
        }

        @media (max-width: 992px) {
            margin-bottom: 20px;
        }
    }

    @media (max-width: 992px) {
        margin-bottom: 80px;
    }
}


.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 500px; 
    font-size: 18px;
    font-weight: bold;
    @media (max-width: 768px) {
        height: 350px;
    }

    @media (max-width: 576px) {
        height: 250px;
    }
  }