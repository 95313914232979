.footer {
    background-color: #292929;

    &__inner {
        padding: 40px 0 20px;
        color: #F9FCFC;

        @media (max-width: 576px) {
            padding: 40px 20px 20px;
        }
    }

    &__text {
        color: #fff;
        margin-top: 20px;
        max-width: 350px;
        font-size: 12px;
        font-weight: 300;
        line-height: 1.8;

        @media (max-width: 1200px) {
            font-size: 11px;
        }

        @media (max-width: 992px) {
            font-size: 12px;
            max-width: 100%;
        }
    }
    
    &__license {
        color: #fff;
        max-width: 350px;
        font-size: 12px;
        font-weight: 300;
        line-height: 1.8;

        @media (max-width: 1200px) {
            font-size: 11px;
        }

        @media (max-width: 992px) {
            font-size: 12px;
            max-width: 100%;
        }
    }

    &__social {
        margin-top: 30px;

        ul {
            display: -ms-flexbox;
            display: flex;
            -ms-flex-pack: start;
            justify-content: flex-start;
        }

        li {
            list-style: none;
            margin-right: 10px;

            a {
                opacity: 0.5;
                transition: 0.4s all;

                &:hover {
                    opacity: 1;
                }
            }
        }

        @media (max-width: 992px) {
            margin-bottom: 40px;
        }
    }

    &__title {
        font-size: 15px;
        color: #fff;
        font-weight: 700;
        margin-bottom: 20px;

        @media (max-width: 768px) {
            margin-top: 20px;
        }
    }

    &__links {
        display: block;
        color: #fff;
        font-size: 13px;
        line-height: 2.5;
        font-weight: 300;
        text-decoration: none;
        opacity: 0.6;
        transition: 0.4s all;
        background-color: transparent;
        border: none;
        &:hover {
            opacity: 1;
            color: inherit;
        }

        img {
            margin-right: 10px;
        }

        @media (max-width: 1200px) {
            font-size: 11px;
        }
    }

    &__credits {
        margin-top: 40px;
        border-top: 1px solid #999999;
        padding-top: 20px;
        text-align: center;
        color: #999999;
        font-size: 13px;
        font-weight: 300;
    }
}
