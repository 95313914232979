$gainsboro: #DCDCDC;
$blue: #4d7fd2;
$light-gray: #ced4da;
$yellow-light: #ffea9e;
$yellow-darker: #ffea9e;
$yellow: #FFB53F;
$grey: #79807F;
$dark-gray: #707070;
$color-reg-form: #F5F5F5;
$dirty-white: #D6D6D6;
$box-shadow: #00000073;
$light-blue: #10CAD1;
$green-light: #00B5BC;
$red: #FF3048;
$dirty-black: #1A1A1A;
$green: #00BA07;
$black-gray: #141414;
// grid

$grid-small: 8px;
$grid-half: 16px;
$grid-medium: 24px;
$grid: 32px;

// text
$font-size-normal: 15px;
$font-size-big: 20px;
$font-size-small: 13px;

$font-weight-normal: 400;
$font-weight-semi: 600;

$font-heading-1: 24px;
$font-heading-2: 20px;
$font-heading-3: 18px;
$font-heading-4: 16px;
$font-heading-5: 15px;
$font-heading-6: 14px;

.wrapper-acceptation {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 62vh;
    padding: 20px;
  }
  
  .acceptation-email {
  width: 800px;

  box-shadow: 0px 6px 20px rgba(41, 41, 41, 0.15);
  .inner-container {
    width: 100%;
    height: 300px;
    margin-bottom: $grid + 48px;
    padding: 38px 45px 8 * $grid-small 45px;
    @media screen and (max-width: 450px) {
      padding: 16px;
    }
    &_header {
      font: bold 20px/24px Montserrat-Bold;
      letter-spacing: -1px;
      color: $black-gray;
      list-style: none;
      margin: 0 0 37px 0;
      padding: 0 25%;

      @media screen and (max-width: 1290px){
        font-size: 16px;
      }
      @media screen and (max-width: 600px){
        font-size: 14px;
        padding: 0;
      }
    }
    &_status-block {
      background-color: rgba(255, 181, 63, 0.2);
      border-radius: 5px;
      text-align: left;
      font: normal 14px/16px Roboto-Regular;
      letter-spacing: 0;
      color: $yellow;
      padding-left: 20px;
      padding-top: 17px;
      padding-bottom: 17px;
      margin-top: 55px;
      @media screen and (max-width: 450px) {
        font-size: 10px;
      }
    }
    &__footer {
      text-align: center;
      margin-top: 30px;
      padding-left: $grid-medium;
      @media screen and (max-width: 450px) {
        padding-left: $grid-small;
      }
      ul {
        list-style: none;
        text-align: left;
        border-left: 2px solid $grey;
        padding-left: $grid-medium;
        @media screen and (max-width: 450px) {
          padding-left: $grid-small;
        }
        li {
          font: normal 20px/24px Roboto-Regular;
          color: $black-gray;
          @media screen and (max-width: 1400px) {
            font-size: 18px;
          }
          @media screen and (max-width: 450px) {
            font-size: 10px;
          }
        }
      }
    }
  }

  .color-green-light {
    color: $green-light;
  }

  .display-flex {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    div {
      width: 50%;
      color: $dark-gray;
      margin-bottom: $grid-small;
      word-break: break-word;
      @media screen and (max-width: 1400px) {
        font-size: 18px;
      }
      @media screen and (max-width: 450px) {
        font-size: 10px;
      }
    }
  }

  .return-button {
    color: white;
    background-color: $yellow;
    outline: none;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    padding: $grid-small $grid;
    float: right;
    margin-top: $grid-half;
    font-weight: bold;
  }

  .return-button {
    background-color: $light-blue;
    text-decoration: none;
  }

  .bold {
    font-weight: bold;
  }

}

.merchant-rejected {
  padding: 10px;
  background-color: $red;
  color: #fff;
  font-size: 20px;
}

.merchant-waited {
  padding: 10px;
  background-color: $yellow;
  color: #fff;
  font-size: 20px;
}
