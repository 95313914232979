@import './../../styles/variables.scss';

.word {
    flex-wrap: wrap;
    width: 130px;
}

.wrapper {
    position: relative;
    transition: transform 0.8s;
    transform-style: preserve-3d;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.front, .back {
    backface-visibility: hidden;
    transition: transform 0.6s;
}

.front {
    background: red;
    transform: rotateY(0deg);
}

.back {
    position: absolute;
    top: 15px;
    left: 0;
    transform: rotateY(180deg);
    border-radius: 15px;
    height: 240px;
    width: 100%;
    box-shadow: 0 0 0 1px rgba(153, 153, 153, 0.5);
    background-color: #fff;
    transition: 0.4s all;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    @media (max-width: 1200px) {
        height: 240px !important;   
    }

    @media (max-width: 994px) {
        height: 200px !important;
    }
    @media (max-width: 574px) {
        height: 200px !important;
    }
}

.backInner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 15px;
}

.flipped .front {
    transform: rotateY(-180deg);
}

.flipped .back {
    transform: rotateY(0deg);
}

.slider {
    background-image: url('./../../assets/bg-top.png');
    background-repeat: no-repeat;
    background-position: top center;
    height: 700px;

    &__left {
        padding: 150px 0 150px 20px;
        height: 600px;

        @media (max-width: 1200px) {
            padding: 150px 0 150px 0px;
        }

        @media (max-width: 992px) {
            padding-top: 30px;
        }

        @media (max-width: 768px) {
            height: 100%;
            padding: 40px 0 0 0;
        }
    }

    &__title {
        font-size: 36px;
        font-weight: 900;
        color: #292929;
        margin-bottom: 30px;
        line-height: 1.2;

        @media (max-width: 1200px) {
            font-size: 30px;
        }
    }
      
    &__text {
        margin-top: 30px;
        font-size: 16px;
        max-width: 400px;
        font-weight: 400;
        color: #292929;

        @media (max-width: 1200px) {
            font-size: 14px;
        }
    }
      
    &__apps {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: start;
        justify-content: flex-start;
        margin-top: 40px;
    
        img {
            margin-right: 20px;
        }
    }
  
    &__right {
        text-align: left;
        padding-top: 100px;

        img {
            @media (max-width: 1200px) {
                height: 80%;
            }

            @media (max-width: 992px) {
                margin-top: 30px;
                height: 280px;
            }
        }

        @media (max-width: 992px) {
            overflow: hidden;
            padding-top: 30px;
        }
    }

    @media (max-width: 1200px) {
        height: 700px;
    }

    @media (max-width: 992px) {
        background-size: 100%;
        height: 400px;
    }

    @media (max-width: 768px) {
        height: 450px;
    }
}

.services {
    @media (max-width: 574px){
        .wrapper {
            padding: 0 80px;
        }
    }
    
    @media (max-width: 420px) {
        .wrapper {
            padding: 0 40px;
        }
    }

    padding: 60px 0 100px 0;
    &__inner {
        margin-top: 60px;

        @media (max-width: 768px) {
            margin-top: 30px;
        }
    }

    &__item {
        margin: 15px 0;
        padding: 30px;
        border-radius: 15px;
        height: 240px;
        box-shadow: 0 0 0 1px rgba(153, 153, 153, 0.5);
        background-color: #fff;
        transition: 0.4s all;

        @media (max-width: 1200px) {
            height: 240px !important;   
        }

        @media (max-width: 994px) {
            height: 200px !important;
        }
        @media (max-width: 574px) {
            height: 200px !important;
        }
        p {
            font-size: 14px;
            font-weight: 500;
            color: #707070;
            line-height: 1.1;
            @media (max-width: 1200px) {
                font-size: 12px;
                line-height: 1.1;
            }

            @media (max-width: 992px) {
                font-size: 11px;
                line-height: .9;

            }

            @media (max-width: 576px) {
                font-size: 13px;
            }
        }
        
        &-header {
            position: relative;
            h4 {
                font-size: 20px;
                font-weight: 800;
                color: $black;
                margin-bottom: 40px;
                height: 72px;
                @media (max-width: 1200px) {
                    font-size: 18px;
                    margin-bottom: 30px;
                }

                @media (max-width: 992px) {
                    font-size: 15px;
                    margin-bottom: 20px;
                    width: 60pxw;
                }

                @media (max-width: 576) {
                    font-size: 18px;
                }
            }

            img {
                position: absolute;
                right: -10px;
                top: -10px;
                transition: 0.4s all;
                @media (max-width: 1200px) {
                    max-height: 100px;
                }

                @media (max-width: 992px) {
                    max-height: 60px;
                }
            }
        }

        &:hover {
            box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
            background-color: #10CAD1;
            border: none;

            h4 {
                color: #fff;
            }

            p {
                color: #fff;
            }

            img {
                top: -20px;
            }
        }

        @media (max-width: 1200px) {
            height: 200px;
        }

        @media (max-width: 992px) {
            padding: 20px;
        }
    }

    @media (max-width: 768px) {
        padding-bottom: 60px;
    }
}

.c2c {
    background-color: #f5f5f5;
    padding: 100px 0;

    &__left {
        padding-left: 70px;

        @media (max-width: 1200px) {
            padding-left: 0;
        }

        @media (max-width: 576px) {
            padding: 10px;
        }
    }

    &__inner {
        margin-top: 50px;
    }

    &__text {
        position: relative;
        margin-bottom: 20px;
        line-height: 24px;

        img {
            position: absolute;
            top: 0;
            left: 0;
        }

        &-desc {
            padding-left: 40px;
            font-size: 14px;
            color: #707070;

            @media (max-width: 576px) {
                padding-left: 30px;
                font-size: 12px;
            }
        }

        &-num {
            padding-right: 100px;
            font-size: 14px;
            font-weight: 700;
            color: #292929;

            @media (max-width: 1200px) {
                padding-right: 0;
                margin-bottom: 15px;
            }

            @media (max-width: 576px) {
                margin-bottom: 0;
                font-size: 12px;
            }
        }

        @media (max-width: 576px) {
            margin-bottom: 20px;
            line-height: 18px;
        }
    }

    &__right {
        position: relative;
        height: 350px;

        @media (max-width: 992px) {
            margin-top: 40px;
        }

        @media (max-width: 576px) {
            height: 320px;
        }
    }

    &__box {
        position: absolute;
        left: 60px;
        width: 350px;
        height: 220px;
        border-radius: 20px;
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);

        &-blue {
            padding: 20px;
            top: 0;
            background-color: #10CAD1;
            z-index: 10;
            background-image: url('./../../assets/c2c-bg.svg');
            background-repeat: no-repeat;
            background-position: bottom right;
        }

        &-orange {
            padding: 150px 20px 20px 20px;
            top: 82px;
            left: 158px;
            background-color: #FFB53F;
            z-index: 9;
        }

        &-title {
            font-size: 16px;
            font-weight: 700;
            color: #fff;
        }

        &-subtitle {
            font-size: 10px;
            color: #fff;
            font-weight: 300;
        }

        &-phone {
            padding-top: 40px;

            p {
                color: #fff;
                font-size: 25px;
                font-weight: 200;
            }
        }

        @media (max-width: 576px) {
            left: 0;
            width: 320px;
            height: 200px;
        }
    }

    &__input {
        background-color: rgba(0, 0, 0, 0.15);
        border: none;
        border-radius: 5px;
        padding: 0 10px;
        color: #fff;
        font-size: 25px;
        font-weight: 200;
        -moz-appearance: textfield;
        outline: none;

        &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
            -webkit-appearance: none;
        }

        &::-webkit-input-placeholder {
            color: #fff;
            opacity: 0.5;
        }

        &::-moz-placeholder {
            color: #fff;
            opacity: 0.5;
        }

        &:-ms-input-placeholder {
            color: #fff;
            opacity: 0.5;
        }

        &::-ms-input-placeholder {
            color: #fff;
            opacity: 0.5;
        }

        &::placeholder {
            color: #fff;
            opacity: 0.5;
        }

        &:focus {
            background-color: rgba(0, 0, 0, 0.25);
        }

        &-blue {
            width: 200px;
            margin-left: 10px;
        }

        &-orange {
            width: 100px;
            margin-right: 10px;
            text-align: right;
        }
    }

    &__agreement {
        font-size: 14px;
        font-weight: 300;
        color: #707070;

        a {
            color: #10CAD1;
            text-decoration: underline;
            font-weight: 500;

            &:hover {
                text-decoration: none;
            }
        }

        @media (max-width: 1200px) {
            font-size: 11px;
        }

        @media (max-width: 576px) {
            margin-bottom: 30px;
        }
    }
    
    &__submit {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: justify;
        justify-content: space-between;
        width: 200%;

        @media (max-width: 1200px) {
            width: 180%;
        }
    
        @media (max-width: 993px) {
            width: 100%;
        }

        @media (max-width: 576px) {
            -ms-flex-direction: column;
            flex-direction: column;
        }
    }

    @media (max-width: 768px) {
        padding: 60px 0;
    }

    @media (max-width: 576px) {
        padding-bottom: 30px;
    }
}

.dev {
    padding: 100px 0;

    &__inner {
        margin-top: 50px;
    }

    &__box {
        min-height: 280px;
        padding: 30px 30px 20px;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
        border-radius: 15px;

        h5  {
            margin-top: 20px;
            font-size: 16px;
            font-weight: 700;
            color: #292929;

            @media (max-width: 1200px) {
                padding-left: 60px;
                margin-top: 5px;
                margin-bottom: 30px;
            }

            @media (max-width: 992px) {
                padding-left: 0;
                margin-top: 20px;
                margin-bottom: 20px;
            }
        }

        p {
            margin-top: 10px;
            font-size: 11px;
            font-weight: 400;
            color: #292929;
            line-height: 1.2;

            @media (max-width: 1200px) {
                font-size: 12px;
            }

            @media (max-width: 992px) {
                font-size: 12px;
            }
        }

        a {
            display: block;
            padding: 5px 10px;
            margin-top: 20px;
            font-size: 12px;
            line-height: 24px;
            border-radius: 5px;
            font-weight: 600;
            text-decoration: none;
            background-color: transparent;
            transition: 0.4s all;

            img {
                @media (max-width: 1200px) {
                    display: none;
                }

                @media (max-width: 992px) {
                    display: inline-block;
                }
            }
        }

        &-large {
            background-color: #f5f5f5;
            position: relative;
            overflow: hidden;

            img {
                width: 100%;
                position: absolute;
                top: 50%;
                left: 50%;
                z-index: 1;
                -webkit-transform: translate3d(-50%, -50%, 0);
                transform: translate3d(-50%, -50%, 0);
            }
        }

        &-blue {
            background-color: #F9FCFC;

            a {
                color: #10CAD1;

                &:hover {
                    background-color: rgba(16, 202, 209, 0.15);
                }
            }
        }

        &-orange {
            background-color: #FFF5E5;

            a {
                color: #FFB53F;

                &:hover {
                    background-color: rgba(255, 181, 63, 0.15);
                }
            }
        }

        &-icon {
            display: block;

            @media (max-width: 1200px) {
                position: absolute;
                top: 20px;
                left: 20px;
            }

            @media (max-width: 992px) {
                position: static;
                top: 0;
                left: 0;
            }
        }

        @media (max-width: 1200px) {
            padding: 20px;
            position: relative;
        }

        @media (max-width: 992px) {
            margin: 10px 0;
            padding: 30px;
        }

        @media (max-width: 576px) {
            min-height: 200px;
        }
    }
}

.clients {
    padding: 100px 0;
    background-color: #f5f5f5;

    &__cs {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: center;
        justify-content: center;
        margin-top: 40px;
        padding: 20px 20px;
    }

    &__item {
        margin: 0 15px;
        padding: 15px;
        width: 130px;
        height: 130px;
        background: #fff;
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
        border-radius: 10px;
        text-align: center;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: center;
        justify-content: center;
        -ms-flex-align: center;
        align-items: center;

        img {
            display: block;
            object-fit: contain;
        }

        @media (max-width: 1200px) {
            width: 100px;
            height: 100px;
        }

        @media (max-width: 768px) { 
            width: 80px;
            height: 80px;
        }
        @media (max-width: 576px) {
            width: 100px;
            height: 100px;
        }
    }

    @media (max-width: 576px) {
        padding: 50px 0;
    }
}

.appStore {
    height: 40px;
    width: 130px;
}

.devBoxTitle {
    min-height: 39px;
    @media (max-width: 1200px){
        min-height: 58px;
    }
    @media (max-width: 992px){
        min-height: 48px;
    }
    @media (max-width: 768px){
        min-height: 28px;
    }
}

.devBoxDesc {
    min-height: 80px;
    @media (max-width: 1400px){
        min-height: 92px;
    }
    @media (max-width: 1200px){
        min-height: 144px;
    }
    @media (max-width: 1000px) {
        min-height: 144px;
    }
    @media (max-width: 993px) {
        min-height: 144px;
    }
    @media (max-width: 991px) {
        min-height: 87px;
    }
    @media (max-width: 768px){
        min-height: 57px;
    }
}

@media (max-width: 420px) {
    .secTitle {
        margin-top: 120px;
    }
}

@media (max-width: 350px) {
    .secTitle {
        margin-top: 180px;
    }
}

.apps {
    display: flex;
    gap: 10px;
}

.apps_link {
    width: 130px;
}

@media (max-width: 500px) {
    .apps {
        flex-wrap: wrap;
        gap: 5px;
    }
}