.bush {
    margin: 50px 0;

    &__inner {
        background: linear-gradient(90deg, rgb(249, 252, 252) 0%, rgb(245, 245, 245) 50%, rgb(255, 245, 229) 100%);
        height: 400px;
        border-radius: 20px;

        @media (max-width: 992px) {
            height: 300px;
            overflow: hidden;
        }

        @media (max-width: 768px) {
            height: 100%;
        }
    }

    &__left {
        padding: 70px;

        @media (max-width: 992px) {
            padding: 35px;
        }
    }

    &__right {
        padding-right: 80px;
        background-image: url('./../../assets/buah-s.svg');
        background-repeat: no-repeat;
        background-position: center center;

        &-pic {
            height: 400px;
            background-image: url('./../../assets/bush-p.png');
            background-repeat: no-repeat;
            background-position: bottom center;

            @media (max-width: 992px) {
                background-size: 90%;
                height: 96%;
            }
        }

        @media (max-width: 992px) {
            background-size: 96%;
            padding-right: 10px;
        }
    }
}

.bizcab {
    margin: 100px 0;

    &__inner {
        &-img {
            @media (max-width: 1200px) {
                margin-top: 40px;
                width: 500px;
            }

            @media (max-width: 992px) {
                margin-top: 0;
                margin-bottom: 40px;
            }

            @media (max-width: 576px) {
                width: 300px;
            }
        }

        @media (max-width: 992px) {
            padding: 0 50px;
        }

        @media (max-width: 768px) {
            padding: 0;
        }

        @media (max-width: 576px) {
            padding: 10px;
        }
    }

    &__content {
        margin-top: 60px;
    }
}

.odeqeb {
    &__inner {
        background-color: #f2f2f2;
        border-radius: 20px;
        margin-bottom: 350px;

        @media (max-width: 992px) {
            margin-bottom: 100px;
        }
    }

    &__left {
        padding: 40px 40px 60px 60px !important;

        @media (max-width: 576px) {
            padding: 10px;
        }
    }

    &__list {
        margin-top: 15px;
        position: relative;
        padding-left: 30px;
        font-size: 15px;
        color: #292929;

        &::before {
            content: url('./../../assets/icons/done.svg');
            top: 0;
            left: 0;
            position: absolute;
        }

        @media (max-width: 576px) {
            font-size: 12px;
        }
    }

    &__cards {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: center;
        justify-content: center;
        position: relative;
        z-index: 5;

        @media (max-width: 992px) {
            -ms-flex-direction: column;
            flex-direction: column;
            position: static;
        }
    }

    &__card {
        margin-left: 15px;
        margin-right: 15px;
        margin-bottom: -250px;
        width: 30%;
        min-height: 480px;
        padding: 30px;
        background: #F9FCFC;
        border-radius: 15px;
        transition: 0.4s all;

        &:hover {
            box-shadow: 0px 4px 20px rgba(41, 41, 41, 0.15);
            background-color: #fff;
            -webkit-transform: translateY(-10px);
            transform: translateY(-10px);

            @media (max-width: 1200px) {
                -webkit-transform: translateY(0);
                transform: translateY(0);
            }
        }

        &-pic {
            height: 50%;

            img {
                @media (max-width: 1200px) {
                    width: 90%;
                }

                @media (max-width: 992px) {
                    width: 100%;
                }
            }

            @media (max-width: 1200px) {
                height: 40%;
            }

            @media (max-width: 992px) {
                width: 30%;
                height: 100%;
            }

            @media (max-width: 768px) {
                padding-top: 40px;
            }

            @media (max-width: 576px) {
                width: 100%;
            }
        }

        &-title {
            font-size: 16px;
            font-weight: 700;
            margin: 15px auto;

            @media (max-width: 992px) {
                margin-top: 0;
            }
        }

        &-desc {
            font-weight: 400;
            font-size: 11px;
            text-align: justify;
            line-height: 1.2;
        }

        &-content {
            @media (max-width: 992px) {
                width: 70%;
                padding-left: 30px;
            }

            @media (max-width: 576px) {
                width: 100%;
                padding-left: 0;
            }
        }

        @media (max-width: 1200px) {
            margin-left: 12px;
            margin-right: 12px;
            padding: 20px;
        }

        @media (max-width: 992px) {
            display: -ms-flexbox;
            display: flex;
            -ms-flex-pack: start;
            justify-content: start;
            width: 97%;
            min-height: 200px;
            margin-bottom: 15px;
        }

        @media (max-width: 576px) {
            display: block;
            text-align: center;
            -ms-flex-pack: center;
            justify-content: center;
            width: 93%;
        }
    }

    &__img {
        @media (max-width: 1200px) {
            margin-top: 50px;
            width: 400px;
        }
    }
}

.sui {
    margin-bottom: 100px;

    &__inner {
        background: #F2F2F2;
        border-radius: 20px;

        img {
            @media (max-width: 992px) {
                margin-top: 0;
            }
        }

        &-img {
            @media (max-width: 1200px) {
                margin-top: 40px;
                display: block;
                width: 100%;                
            }
        }

        @media (max-width: 992px) {
            padding: 0 50px;
        }

        @media (max-width: 576px) {
            padding: 10px;
        }
    }

    &__right {
        padding: 40px;
    }

    &__but {
        width: 150px;
        height: 50px;
        box-shadow: 0px 0px 0px 1px rgba(41, 41, 41, 0.1);
        border-radius: 10px;
        position: relative;
        padding: 10px 10px 10px 55px;
        text-decoration: none;
        cursor: pointer;
        transition: 0.4s all;
        
        &:hover {
            box-shadow: 0px 0px 15px 1px rgba(41, 41, 41, 0.1);
        }

        img {
            position: absolute;
            top: 10px;
            left: 10px;
            width: 30px;
            height: 30px;
            transition: 0.4s all;
        }

        p {
            font-weight: 700;
            font-size: 14px;
            line-height: 15px;

            @media (max-width: 576px) {
                font-size: 16px;
                font-weight: 700;
            }
        }

        &-api {
            background: #F9FCFC;
            margin-right: 20px;

            P {
                color: #0eb3b9;
            }

            @media (max-width: 576px) {
                margin-right: 0;
                margin-bottom: 30px;
            }
        }

        &-modules {
            background: #FFF5E5;

            p {
                color: #FFB53F;
            }
        }

        @media (max-width: 576px) {
            width: 170px;
            padding-left: 55px;
        }
    }

    &__btns {
        @media (max-width: 576px) {
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        }
    }

    @media (max-width: 768px) {
        margin-bottom: 60px;
    }
}

.fmt {
    margin-bottom: 100px;

    &__left {
        padding: 70px;
        
        p {
            margin-top: 40px;
            font-size: 15px;
            color: #292929;
        }

        h6 {
            margin-top: 50px;
            font-weight: 700;
            font-size: 15px;
            color: #292929;
        }

        @media (max-width: 1200px) {
            padding: 40px;
        }

        @media (max-width: 576px) {
            padding: 10px;
        }
    }

    &__right {
        @media (max-width: 576px) {
            padding-top: 40px;
        }

        img {
            @media (max-width: 576px) {
                width: 100%;
            }
        }
    }

    &__inner {
        @media (max-width: 992px) {
            display: -ms-flexbox;
            display: flex;
        }
    }

    &__apps {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: start;
        justify-content: flex-start;
        margin-top: 40px;
    
        @media (max-width: 480px) {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: 10px;
            margin-bottom: 20px;
        }
        
        img {
            margin-right: 20px;
        }
    }
}