.icon-text {
    position: relative;
    margin-bottom: 20px;
    line-height: 24px;

    img {
        position: absolute;
        top: 0;
        left: 0;
    }

    &__desc {
        padding-left: 40px;
        font-size: 14px;
        color: #707070;

        @media (max-width: 576px) {
            padding-left: 30px;
            font-size: 12px;
        }
    }
}