@import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
@import './variables.scss';

body, p, blockquote {
  margin: 0;
  padding: 0;
}

a img, iframe {
  border: none;
}

/* Headers
------------------------------*/
h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
    font-size: 100%;
}

h1 {
    font-size: 40px;
    font-weight: 900;
    color: #292929;
    margin-bottom: 30px;
    line-height: 1.2;

    @media (max-width: 1200px) {
        font-size: 30px;
    }
}

/* Lists
------------------------------*/
ul, ol, dl, li, dt, dd {
  margin: 0;
  padding: 0;
}

/* Forms
------------------------------*/
form, fieldset {
  margin: 0;
  padding: 0;
}

fieldset {
  border: 1px solid #000;
}

legend {
  padding: 0;
  color: #000;
}

input, textarea, select {
  margin: 0;
  padding: 1px;
  font-size: 100%;
  font-family: inherit;
}

select {
  padding: 0;
}

body {
    // font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #292929;
}

.page-slider {
    p {
        margin-top: 30px;
        font-size: 16px;
        max-width: 400px;
        font-weight: 400;
        color: #292929;
    
        @media (max-width: 1200px) {
            font-size: 14px;
        }
    }
}

.section__header {
    position: relative;
}

.section__header-text {
    padding-left: 60px;
}

.section__header img {
    position: absolute;
    left: 0;
    top: 0;
}

.section__title {
    font-weight: 800;
    font-size: 32px;
    color: #292929;
    padding-left: 60px;
}

.alttitle {
    font-weight: 800;
    font-size: 32px;
    color: #292929;
    position: relative;
    z-index: 2;
}

.alttitle img {
  position: absolute;
  z-index: -1;
  left: -12px;
  top: -5px;
}

.button {
  font-size: 14px;
  font-weight: 700;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  text-decoration: none;
  transition: 0.4s all;
  line-height: 24px;
}

.button-empty {
  margin-right: 20px;
  background-color: #fff;
  box-shadow: 0 0 0 1px #10CAD1;
  color: #10CAD1;
}

.button-empty:hover {
  background-color: #FFD084;
  color: #fff;
  box-shadow: none;
}

.button-c2c {
  color: #fff;
  background-color: #10CAD1;
}

.button-c2c:hover {
  background-color: #FFB53F;
  color: #fff;
}

.carousel-control-next, .carousel-control-prev {
  width: 5%;
}

.partners {
  padding: 100px 0;
}

.partners__cards {
  display: -ms-flexbox;
  display: flex;
  padding-top: 60px;
}

.partners__cards-gr {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 30px;
  height: 100%;
  background: linear-gradient(180deg, rgba(196, 196, 196, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
  border-radius: 20px;
}

.partners__cards-gr h5 {
  min-height: 40px;
  font-weight: 400;
  font-size: 16px;
}

.partners__cards-gr p {
  font-size: 0px;
  font-weight: 400;
}

.partners__cards-gr p img {
  right: 30px;
  bottom: 0;
  width: 35px;
  position: absolute;
  opacity: 0;
  -webkit-transform: translateX(-20px);
  transform: translateX(-20px);
  transition: 0.4s all;
}

.partners__cards-gr a {
  text-decoration: none;
  color: #fff;
}

.partners__cards-gr a:hover {
  text-decoration: underline;
  color: #fff;
}

.partners__panel {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 20px;
  height: 400px;
  color: #fff;
  cursor: pointer;
  -ms-flex: 0.5;
  flex: 0.5;
  margin: 10px;
  position: relative;
  transition: all 300ms ease-in;
}

.partners__panel img {
  margin-bottom: 20px;
  display: block;
  height: 64px;
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.gorset {
  -ms-flex: 5;
  flex: 5;
}

.gostertext h5 {
  font-size: 30px;
  font-weight: 700;
  transition: 0.3s all;
}

.gostertext p {
  width: 85%;
  font-size: 14px;
  transition: 0.3s all;
}

.gostertext p img {
  opacity: 1;
  position: absolute;
  right: 30px;
  bottom: 0;
  width: 35px;
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.triangle {
  position: absolute;
  top: 20px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-right: 300px solid transparent;
  border-left: 300px solid transparent;
  border-top: 300px solid rgba(153, 153, 153, 0.4);
  border-bottom: 0;
}

.pnavindex {
  display: none;
}

.nav-link {
  color: $main;

  &:hover {
    color: $orange;
  }

  &.active {
    color: #fff;
    background-color: $main !important;
  }
}

.modal-title {
  font-weight: 500 !important;
}

.giris {
  padding: 30px 0 10px;
  // padding: 50px 0 30px;
}

.giris__link {
  display: block;
  text-decoration: none;
  font-size: 14px;
  color: #10CAD1;
  font-weight: 500;
  margin-top: 20px;
  margin-left: 10px;
  transition: 0.4s all;
}

.giris__link:hover {
  color: #FFB53F;
  text-decoration: underline;
}

.giris__bas {
  color: #10CAD1;
}

.giris__bas:hover {
  color: #FFB53F;
}

.p-password, 
.p-component,
.p-inputwrapper,
.p-inputwrapper-filled,
.p-input-icon-right 
{
  width: 100%;
}

.p-password-panel {
  width: 220px;
}

.p-inputtext, .p-password-input {
  height: 38px !important;
}

.button576 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 290px;
}

@media (max-width: 1200px) {
  .button {
      font-size: 12px;
      font-weight: 700;
      padding: 5px 10px;
  }

  .carousel-control-next, .carousel-control-prev {
      width: 2%;
  }

  .partners__cards-gr {
      padding: 20px;
  }

  .partners__panel {
      margin: 7px;
  }

  .gostertext h5 {
      font-size: 25px;
  }

  .gostertext p {
      font-size: 12px;
  }

  .alttitle {
      font-size: 30px;
  }

  .alttitle img {
      width: 40px;
      height: 40px;
      left: -12px;
      top: -2px;
  }
}

@media (max-width: 992px) {
  .button576 {
      margin-bottom: 20px;
      padding-left: 20px;
  justify-content: flex-start;

  }

  .partners {
      padding: 70px;
  }

  .partners__cards {
      display: none;
  }

  .pnavindex {
      display: block;
  }

  .button-c2c {
      padding: 10px;
  }
}

@media (max-width: 768px) {
  .carousel-control-next, .carousel-control-prev {
      width: 10%;
  }

  .section__title {
      font-size: 28px;
      padding-left: 45px;
  }

  .section__header img {
      width: 30px;
  }

  .section__header-text {
      padding-left: 45px;
      font-size: 12px;
  }


  .alttitle {
      margin-top: 40px;
  }

  .triangle {
      border-right: 200px solid transparent;
      border-left: 200px solid transparent;
      border-top: 200px solid hsla(0deg, 0%, 60%, 0.4);
  }
}

@media (max-width: 576px) {
  .section__title {
      font-size: 22px;
      padding-left: 70px;
  }

  .section__header img {
      width: 50px;
  }

  .section__header-text {
      padding-left: 70px;
      margin-top: 10px;
  }

  .partners {
      padding: 0;
      margin-top: 50px;
  }

  .triangle {
      border-right: 100px solid transparent;
      border-left: 100px solid transparent;
      border-top: 100px solid hsla(0deg, 0%, 60%, 0.4);
  }

  .alttitle {
      margin-top: 10px;
      font-size: 25px;
      padding-left: 18px;
  }

  .alttitle img {
      left: 10px;
      width: 35px;
      height: 35px;
  }
 
  .p-password, 
  .p-component,
  .p-inputwrapper,
  .p-inputwrapper-filled,
  .p-input-icon-right  {
    flex-wrap: wrap;
    min-width: 100%;
  }
}

.p-inputtext:enabled:focus {
    outline: 0 none;
    outline-offset: 0;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.p-inputtext:enabled:focus {
  border-color: #fff !important;
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  border-radius: 0.375rem; 
}

.modal-header {
  border-bottom: 0 none;
}

.modal-footer {
  border-top: 0 none;
}

.p-inputtext:enabled:hover {
  border-color: #ced4da;
}

.passError {
  color: red;
}

.error_message {
  color: red;
  margin-top: 5px;
}

.dropdown-item.active, .dropdown-item:active {
  text-decoration: none;
  background-color: #10CAD1;
}
