.content {
  max-width: 1200px;
  padding: 0 20px;
  padding-bottom: 30px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.item-list-wrapper {
  &__items {
    display: flex;
    flex-wrap: wrap;
    margin-right: -22px;
    &--margin-right10 {
      margin-right: -10px;
    }
    &--margin-right32 {
      margin-right: -32px;
    }
    &--margin-right50 {
      margin-right: -50px;
    }
  }
  &__item {
    width: calc(25% - 22px);
    margin-right: 22px;
    &--margin-right10 {
      width: calc(25% - 10px);
      margin-right: 10px;
    }
    &--margin-right32 {
      width: calc(25% - 32px);
      margin-right: 32px;
    }
    &--margin-right50 {
      width: calc(25% - 50px);
      margin-right: 50px;
    }
  }
}

.item-list-wrap {
  text-decoration: none;
}

.item-list-wrap:hover {
  background: #efeff3;
  cursor: pointer;
  text-decoration: none;

  .payment-item__label {
    //color: #10CAD1;
    font-size: 18px;
    font-weight: 500;
    --falcon-font-sans-serif: 'Manrope', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
    color: rgba(var(--falcon-gray-900-rgb), var(--falcon-text-opacity)) !important;
  }
}

.payment-item__label {
  color: #5d6776;
  margin-top: 15px;
  display: flex;
  justify-content: center;
  font-size: 18px;
  font-weight: 500;
}

.itemWrapper {
  display: flex;
  align-items: center;
  // justify-content: flex-start;
  // justify-content: flex-start;
  text-align: center;
  // gap: 10px;
}

.itemBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 285.5px;
  padding: 20px 0px;
  text-decoration: none !important;
  font-style: 32px;
}

.itemBlock:hover {
  background-color: #eef0f2 !important;
  border-radius: 5px;
}

a {
  text-decoration: none;
  font-size: 18px;
  color: #344050;
  // color: rgba(var(--falcon-gray-900-rgb), var(--falcon-text-opacity)) !important;
}

a:hover {
  color: #10cad1;
  text-decoration: underline;
}

.loading-gif {
  width: 1200px;
  min-height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1539px) {
  .content {
    width: 858px;
  }
  .itemBlock {
    width: 285px;
  }
  .content {
    width: 928px;
  }
}

@media (max-width: 994px) {
  .content {
    width: 688px;
  }
  .itemBlock {
    width: 210px;
  }
}


@media (max-width: 767px) {
  .content {
    max-width: 508px;
    width: 100%;
  }
  .itemBlock {
    width: 468px;
  }
  .itemBlock {
    width: 100%;
  }
  .skeleton {
    display: flex;
    width: 50%;
    margin: 0 auto;
  }
}

