.servicePay {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 70vh;
}

.service-wrapper {
  width: 1200px;
  padding: 20px;
}

.breadcrumb {
  --falcon-breadcrumb-divider: '»';
}

.service_description-field {
  background-color: #f5f5f5;
  border-radius: 3px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: justify;
  margin-bottom: 35px !important;
  // background-image: url('../../assets/img/illustrations/description.svg');
  background-image: url('../../assets/spot-illustrations/description.svg');
  background-repeat: no-repeat;
  background-position: left center;
  background-size: cover;
}

.service_description-title {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 147%;
  color: #10CAD1;
  margin-bottom: 20px;
}

.service_description-text {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 147%;
  color: #5E6E82;

}

.additional-info__container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;


}
.additional-info__container__column {
  width: 33%;
  margin-bottom: 30px;
  padding: 0 10px;
}

.additional-info__container__column-title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 147%;
  color: #5E6E82
}

.additional-info__container__column-text {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 147%;
  color: #10CAD1;
}

.goBack {
  color: #10CAD1;
  margin-bottom: 5px;
  text-decoration: underline;
  cursor: pointer;
  font-weight: 400;
  display: inline-block;
  border: none;
  background-color: #fff;
}

.phone-fields {
  position: relative;
}

.phone-fields > div > .render-picker {

  position: absolute;
  top: 21px;
  left: 0;
}

.react-datepicker-wrapper {
  min-width: 100%;
}

.service_fields-item-title {
  display: flex;
}

.prefix-input.form-react-select .control {
  box-sizing: border-box;
}

.form-react-select__value-container {
  width: 80px;
}
.react-select__control:focus {
  box-shadow: none !important;
}

.service_fields-item .form-react-select__control--is-focused {
  box-shadow: none !important;
  border-color: none !important;
}

.phone-fields > div > div > .form-control {
  // padding-left: 125px;
}

@media screen and (max-width: 900px) {
  .additional-info__container__column {
    width: 50%;
  }
}