.tariffs {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 120px 0 260px 0;
}

.tariffs__inner {
    width: 80%;
    max-width: 1200px;
}

.accordions {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.accordion-button {
    font-weight: 600;
}

.accordion__title {
    font-weight: 600;
    font-size: 16px;

}

.accordion__text {
    max-width: 800px;
}

.commission-table {
    font-family: Arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    max-width: 800px;
    margin: 20px auto;
  }
  
  .commission-table th,
  .commission-table td {
    border: 1px solid black;
    padding: 8px;
    text-align: left;
  }
  
  .commission-table th {
    font-weight: normal;
    font-size: 14px;
  }
  
  .commission-table td:first-child {
    width: 50%;
  }