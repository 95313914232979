/* margins and paddings */
$cover-left: 140px;
$cover-top: 240px;
$large-margin: 60px;
$big-margin: 45px;
$semi-big-margin: 40px;
$medium-margin: 30px;
$normal-margin: 20px;

/* colors */
$black: #292929;
$main: #10CAD1;
$main-black: #00858A;
$gray: #707070;
$orange: #ffb53f;
$google-btn: #dd4b39;
$fb-btn: #3c5a99;
$falcon-primary: #10CAD1;
$falcon-primary-hover: #0da2a7;
$labels-color: #5E6E82;
$titles-color: #344050;
/* sizes */
$nav-height: 84px;

/* gradients */
$falcon-card-gradient: linear-gradient(-45deg, #1970e2, #4695ff);
