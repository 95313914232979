.bush {
    margin: 50px 0;

    &__inner {
        background: linear-gradient(90deg, rgb(249, 252, 252) 0%, rgb(245, 245, 245) 50%, rgb(255, 245, 229) 100%);
        height: 400px;
        border-radius: 20px;

        @media (max-width: 992px) {
            height: 300px;
            overflow: hidden;
        }

        @media (max-width: 768px) {
            height: 100%;
        }
    }

    &__left {
        padding: 70px;

        @media (max-width: 992px) {
            padding: 35px;
        }
    }

    &__right {
        padding-right: 80px;
        background-image: url('./../../assets/buah-s.svg');
        background-repeat: no-repeat;
        background-position: center center;

        &-pic {
            height: 400px;
            background-image: url('./../../assets/bush-p.png');
            background-repeat: no-repeat;
            background-position: bottom center;

            @media (max-width: 992px) {
                background-size: 90%;
                height: 96%;
            }
        }

        @media (max-width: 992px) {
            background-size: 96%;
            padding-right: 10px;
        }
    }
}

.modcards {
    margin-top: 100px;
    margin-bottom: 100px;

    &__card {
        margin-top: 20px;
        background-color: #F9FCFC;
        border-radius: 15px;
        height: 580px;
        padding: 30px;
        position: relative;
        transition: 0.4s all;

        &:hover {
            box-shadow: 0px 4px 20px rgba(41, 41, 41, 0.2);
        }

        &-title {
            font-size: 16px;
            font-weight: 700;
            margin: 15px auto;

            @media (max-width: 768px) {
                font-size: 18px;
            }
        }

        &-desc {
            font-weight: 400;
            font-size: 12px;
            color: #292929;
            line-height: 1.3;

            @media (max-width: 768px) {
                font-size: 13px;
            }
        }

        &-pic {
            height: 160px;
            margin-bottom: 40px;

            img {
                @media (max-width: 1200px) {
                    width: 100%;
                }
            }

            @media (max-width: 1200px) {
                height: 140px;
                margin-bottom: 10px;
            }

            @media (max-width: 992px) {
                height: 180px;
            }

            @media (max-width: 768px) {
                height: auto;
            }
        }

        &-footer {
            position: absolute;
            bottom: 30px;
            left: 30px;
            font-weight: 700;
            font-size: 14px;
            color: #707070;

            @media (max-width: 576px) {
                left: 20px;
            }
        }

        &-new {
            margin-top: 20px;
            background-color: #f5f5f5;
            border-radius: 15px;
            height: 580px;
            padding: 15px;
            opacity: 0.5;
            transition: 0.4s all;

            &:hover {
                opacity: 1;
                box-shadow: 0px 4px 20px rgba(41, 41, 41, 0.2);
            }

            @media (max-width: 768) {
                height: 250px;
            }
        }

        &-dash {
            display: -ms-flexbox;
            display: flex;
            -ms-flex-direction: column;
            flex-direction: column;
            -ms-flex-pack: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            opacity: 0.7;
            border: 1px dashed #707070;
            border-radius: 10px;
            cursor: pointer;
            transition: 0.4s all;

            p {
                margin-top: 20px;
                font-weight: 300;
                font-size: 16px;
                color: #707070;
                text-align: center;
            }
        }

        @media (max-width: 1200px) {
            padding: 20px;
        }

        @media (max-width: 768px) {
            padding: 30px;
        }

        @media (max-width: 576px) {
            height: auto;
            padding: 20px 20px 100px 20px;
        }
    }

    @media (max-width: 768px) {
        margin: 60px 0;
    }
}