@import "../../styles/variables.scss";

.navlinkItem {
    display: flex;
    align-items: center;
    justify-content: center;
}

.login__link {
  display: block;
  text-decoration: none;
  font-size: 14px;
  color: #10CAD1;
  font-weight: 500;
  margin-top: 5px;
  margin-left: 10px;
  transition: 0.4s all;
}

.login__link:hover {
  color: #FFB53F;
  text-decoration: underline;
}

.incorrectMessage {
  color: red;
  padding: 10px 10px 0;
}

.note {
  color: rgb(85, 76, 1);
  display: inline;
}

.noteNumber {
  color: rgb(85, 76, 1);
  text-decoration: solid;
}


.reject {
  color: rgb(223, 85, 92);
  display: inline;
}

.rejectNumber {
  color: rgb(223, 85, 92) !important;
  text-decoration: solid;
}


.navlinkReg {
    color: $main;
    background-color: #fff;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;

  &:hover {
    color: $orange;
  }

  &.active {
    color: #fff;
    background-color: $main !important;
  }
}

.isInvalidEntity {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'><path fill='%23dc3545' d='M7.354 0.646c0.293 0.293 0.293 0.768 0 1.061l-6.5 6.5c-0.293 0.293-0.768 0.293-1.061 0l-1.5-1.5c-0.293-0.293-0.293-0.768 0-1.061l6.5-6.5c0.293-0.293 0.768-0.293 1.061 0l1.5 1.5z'/><path fill='%23dc3545' d='M0.646 0.646c0.293-0.293 0.768-0.293 1.061 0l6.5 6.5c0.293 0.293 0.293 0.768 0 1.061l-1.5 1.5c-0.293 0.293-0.768 0.293-1.061 0l-6.5-6.5c-0.293-0.293-0.293-0.768 0-1.061l1.5-1.5z'/></svg>");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.invalidFeedback {
  display: none;
  width: 100%;
  margin-top: .25rem;
  font-size: .875rem;
  color: #dc3545;
}

.info {
  position: absolute;
  top: 20px;
  right: 10px;
  cursor: help;
  display: flex;
  flex-wrap: wrap;
}

.infoText {
  position: absolute;
  top: -60px;
  right: 0;
  padding: 10px;
  background-color: #10CAD1;
  border-radius: 25px;
  z-index: 100;
  display: none;
  width: 220px;
  color: #fff;
  text-align: center;
}

.info:hover .infoText {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btnCnt {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  min-height: 44px !important;
}

.errorBlock {
  display: flex;
  align-items: center;
}

.btnSendCodeAgain {
  background-color: transparent;
  border: none;
  margin-top: 10px;
  border-bottom: 1px solid #10CAD1;
  color: #10CAD1;
  cursor: pointer;
}

.eyeIcon {
  position: absolute;
  right: 10px;
  bottom: 17px;
  height: 19.2px;
  opacity: 0.8;
}