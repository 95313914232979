.service_fields-item .form-react-select__control--is-focused {
    -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(16, 202, 209, 0.25);
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25) !important;
    border-color: #88e5e8 !important;
}
.form-react-select__control {
    border: 1px solid #d8e2ef !important;
}
.form-react-select__value-container {
    padding-left: 16px;
}