.signup {
    margin: 100px 0;

    &__inner {
        background-color: #f2f2f2;
        border-radius: 20px;
        padding: 50px 20px;

        @media (max-width: 992px) {
            padding: 40px 50px;
        }

        @media (max-width: 576px) {
            padding: 30px 20px;
        }
    }

    &__left {
        text-align: center;

        img {
            @media (max-width: 1200px) {
                width: 100%;
            }
        }

        @media (max-width: 1200px) {
            padding-top: 35px;
        }

        @media (max-width: 992px) {
            padding-bottom: 35px;
        }
    }

    &__sub {
        margin-top: 20px;
        margin-bottom: 30px;
        font-weight: 600;
        font-size: 14px;
        color: #292929;
    }

    &__desc {
        font-weight: 400;
        font-size: 14px;
        color: #292929;
        margin: 10px 0;
        padding-left: 40px;
        position: relative;

        img {
            position: absolute;
            left: 0;
            top: 0;
        }
    }

    &__footer {
        margin-top: 40px;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: start;
        justify-content: flex-start;

        &-r {
            padding-left: 20px;

            a {
                color: #10CAD1;
                text-decoration: none;
                font-size: 14px;
                font-weight: 600;
                transition: 0.4s all;

                &:hover {
                    color: #096e72;
                }
            }
        }
    }
}

@media (max-width: 400px){
    .openCashbox {
        font-size: 10;
        padding: 5px;
        font-weight: 400;
    }
    .imgArrow {
        display: none;
    }

    .signup__footer-r {
        padding-left: 10px;
    }
}

@media (max-width: 333px) {
    .signup__footer {
        flex-direction: column;
        gap: 10px;
    }
    .openCashbox {
        width: 100%;
    }
}